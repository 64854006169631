<template>
<div class="panel-reference">

  <template v-if="published">
    <h2>{{ published.productionName }}</h2>
    <div class="status">
      <span class="validation-pending" v-if="localReference.isValidationPending">À valider : </span>{{ localReference.statusAsLabel }}
    </div>
  </template>
  <template v-else-if="draft">
    <h2>{{ draft.productionName }}</h2>
    <div class="status">
      <span class="validation-pending" v-if="localReference.isValidationPending">À valider : </span>{{ localReference.statusAsLabel }}
    </div>
  </template>

  <accordion v-if="localReference.transitionHistory">
    <accordion-item :opened="false">
      <h3 slot="title" for="form-answers">Historique de validation</h3>
      <history v-model="localReference.transitionHistory['history']" slot="content" workflow="reference"  :state="localReference.transitionState" />
    </accordion-item>
  </accordion>

  <panels 
    withSelectors
    ref="panels"
    name="publishedDraftPanels"
    :withEndButton="false"
    :withNavigationButtons="false"
    :doNotValidatePanel="true"
    >
      
    <panel
      id="panel-draft"
      name="Version en cours d'édition"
      :withTitle="false"
      >
      <validation-observer ref="validatorDraft" v-slot="{ valid }" slim>
        <div>
          <reference-data
            v-if="formType==='generic'"
            v-model="draft"
            :additional-addresses="additionalAddresses"
            :differences="localReference.differencesBetweenDraftAndPublished"
            />
          <bank-data
            v-if="formType==='bank'"
            v-model="draft"
            :additional-addresses="additionalAddresses"
            :differences="localReference.differencesBetweenDraftAndPublished"
            />
        </div>

        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-6 medium-offset-6">
            <trigger-buttons
              object-type="reference"
              :pk="localReference.pk"
              :disabled="!valid"
              :triggers="localReference.triggers"
              @trigger-clicked="triggerClicked"
              @trigger-clicked-with-message="saveReferenceAndSendMessage"
              @trigger-clicked-without-message="saveReferenceAndDoNotSendMessage"
              />
          </div>
        </div>
      </validation-observer>
      
    </panel>
    
    <panel
      v-if="published !== null"
      id="panel-published"
      name="Version publiée"
      :withTitle="false"
      :readOnly="true"
      >
      <validation-observer ref="validatorPublished" slim>
        <div>
          <reference-data
            v-if="formType==='generic'"
            v-model="published"
            :differences="localReference.differencesBetweenDraftAndPublished"
            />
          <bank-data
            v-if="formType==='bank'"
            v-model="published"
            :differences="localReference.differencesBetweenDraftAndPublished"
            />
        </div>
      </validation-observer>
    </panel>
    
  </panels>
  
</div>
</template>

<script>
import apiClient from '@/api/api';
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import ReferenceData from '@/apps/listepro/professional_form/reference_data';
import BankData from '@/apps/listepro/professional_form/bank_data';
import History from '@/apps/listepro/professional_form/history';

import Panel from '@/components/panel';
import Panels from '@/components/panels';
import TriggerButtons from '@/apps/listepro/professional_form/trigger_buttons';

import Accordion from '@/components/accordion';
import AccordionItem from '@/components/accordion_item';

import Vue from 'vue';

export default {
    name: 'PanelReference',
    mixins: [handleFormErrorsMixin],
    provide() {
        return {
            formErrors: this.formErrors,
            formErrorsMessages: this.formErrorsMessages,
        };
    },
    inject: ['formErrors', 'formErrorsMessages', 'userPk'],
    components: {
        Accordion,
        AccordionItem,
        BankData,
        History,
        Panel,
        Panels,
        ReferenceData,
        TriggerButtons,
    },
    props: {
        value: Object,
        formType: String,
        additionalAddresses: { default: () => [], type: Array },
    },
    data() {
        return {
            localReference: null,
            draft: null,
            published: null,
        };
    },
    watch: {
        value: {
            handler(value) {
                if (value) {
                    Vue.set(this, "localReference", value);
                    if (this.formType === 'generic') {
                        Vue.set(this, "draft", value.productionDraft);
                        Vue.set(this, "published", value.productionPublished);
                    } else if (this.formType === 'bank') {
                        Vue.set(this, "draft", value.bankOfferDraft);
                        Vue.set(this, "published", value.bankOfferPublished);
                    }
                } 
            },
            immediate: true,
            deep: true,
        },
        localReference: {
            handler(localReference) {
                this.$emit('input', localReference);
            },
            deep: true,
        },
        draft: {
            handler(value) {
                if (value != null) {
                    if (this.formType === 'generic') {
                        this.localReference.productionDraft = value;
                    } else if (this.formType === 'bank') {
                        this.localReference.bankOfferDraft = value;
                    }
                } else {
                    this.localReference.productionDraft = {};
                    this.localReference.bankOfferDraft = {};
                    this.$refs.validatorDraft.reset();
                    this.$refs.validatorPublished.reset();
                }
                
            },
            deep: true,
        },
        published: {
            handler(value) {
                if (value != null) {
                    if (this.formType === 'generic') {
                        this.localReference.productionPublished = value;
                    } else if (this.formType === 'bank') {
                        this.localReference.bankOfferPublished = value;
                    }
                } else {
                    this.localReference.productionPublished = {};
                    this.localReference.bankOfferPublished = {};
                    this.$refs.validatorDraft.reset();
                    this.$refs.validatorPublished.reset();
                }
            },
            deep: true,
        },
    },
    mounted: function() {
    },
    methods: {
        triggerClicked(action) {

            let url = '/listepro/action-on/reference-data/';
            if (this.formType === 'generic') {
                url = '/listepro/action-on/production-data/';
            }
            else if (this.formType === 'bank') {
                url = '/listepro/action-on/bank-offer-data/';
            }

            let data = null;
            if (this.formType === 'generic') {
                data = this.localReference.productionDraft;
            } else if (this.formType === 'bank') {
                data = this.localReference.bankOfferDraft;
            }
            data.executeAction = action;
            // Needed to create a new reference
            data.userPk = this.userPk;
            
            apiClient.genericSave(
                url,
                data
            )
                .then(response => {
                    this.$emit("reload-references");
                    this.$notify({
                        group: 'root',
                        type: 'success',
                        title: 'Modifications enregistrées',
                    });
                })
                .catch(error => {
                    this.handleFormErrors(error);
                });
        },

        resetErrors() {
            this.resetErrorFields();
            this.formErrors = { value: []};
            this.formErrorsMessages = { value: []};
        },
                
        saveReferenceAndSendMessage(message) {
            console.log(message);
            this.resetErrors();
            if (this.formType === 'generic') {
                this.localReference.productionDraft.executeAction = message.action;
                this.localReference.productionDraft.executeMailSubject = message.subject;
                this.localReference.productionDraft.executeMailContent = message.content;
            } else if (this.formType === 'bank') {
                this.localReference.bankOfferDraft.executeAction = message.action;
                this.localReference.bankOfferDraft.executeMailSubject = message.subject;
                this.localReference.bankOfferDraft.executeMailContent = message.content;
            }

            this.triggerClicked(message.action);          
        },
        saveReferenceAndDoNotSendMessage(message) {
            console.log(message);
            this.resetErrors();
            if (this.formType === 'generic') {
                this.localReference.productionDraft.executeAction = message.action;
                this.localReference.productionDraft.executeNoMail = true;
            } else if (this.formType === 'bank') {
                this.localReference.bankOfferDraft.executeAction = message.action;
                this.localReference.bankOfferDraft.executeNoMail = true;
            }

            this.triggerClicked(message.action);          
        },
    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'

.validation-pending
    font-weight: bold
    font-style: italic

.panel-reference
    :deep(.panel-selectors)
        margin-top: space-md
</style>

<template>
<div class="panel-presentation">
  <template v-if="published !== null">
    <div class="status">
      <span class="validation-pending" v-if="localPresentation.isValidationPending">À valider : {{ localPresentation.statusAsLabel }}</span>
    </div>
  </template>
  <template v-else-if="draft !== null">
    <div class="status">
      <span class="validation-pending" v-if="localPresentation.isValidationPending">À valider : {{ localPresentation.statusAsLabel }}</span>
    </div>
  </template>

  <accordion v-if="localPresentation.transitionHistory">
    <accordion-item :opened="false">
      <h3 slot="title" for="form-answers">Historique de validation</h3>
      <history v-model="localPresentation.transitionHistory['history']" slot="content"  workflow="presentation"  :state="localPresentation.transitionState"/>
    </accordion-item>
  </accordion>

  
  <panels 
    withSelectors
    ref="panels"
    name="publishedDraftPanels"
    :withEndButton="false"
    :withNavigationButtons="false"
    :doNotValidatePanel="true"
    v-on:active-panel="panelChanged"
    >
      
    <panel
      id="panel-draft"
      name="Version en cours d'édition"
      :withTitle="false"
      >
      <validation-observer ref="validator" v-slot="{ valid }" slim>
      
        <div>
          <presentation-data
            v-model="draft"
            :differences="localPresentation.differencesBetweenDraftAndPublished"
            />
        </div>

        <div class="grid-x grid-margin-x"
             >
          <div class="cell medium-6 medium-offset-6">
            <trigger-buttons
              object-type="presentation"
              :pk="localPresentation.pk"
              :disabled="!valid"
              :triggers="localPresentation.triggers"
              @trigger-clicked="triggerClicked"
              @trigger-clicked-with-message="savePresentationAndSendMessage"
              />
          </div>
        </div>
          
      </validation-observer>      
    </panel>
    
    <panel
      id="panel-published"
      name="Version publiée"
      :withTitle="false"
      :readOnly="true"
      >
      <div>
        <presentation-data
          v-model="published"
          :differences="localPresentation.differencesBetweenDraftAndPublished"
          :Xdisabled="true"
          />
      </div>
    </panel>
    
  </panels>
  
</div>
</template>

<script>
import apiClient from '@/api/api';
import { handleFormErrorsMixin } from '@/mixins/handle_form_errors_mixin';

import PresentationData from '@/apps/listepro/professional_form/presentation_data';
import TriggerButtons from '@/apps/listepro/professional_form/trigger_buttons';

import Panel from '@/components/panel';
import Panels from '@/components/panels';

import History from '@/apps/listepro/professional_form/history';
import Accordion from '@/components/accordion';
import AccordionItem from '@/components/accordion_item';


export default {
    name: 'PanelPresentation',
    mixins: [handleFormErrorsMixin],
    provide() {
        return {
            formErrors: this.formErrors,
            formErrorsMessages: this.formErrorsMessages,
        };
    },
    inject: ['formErrors', 'formErrorsMessages', 'userPk'],
    components: {
        Accordion,
        AccordionItem,
        History,
        Panel,
        Panels,
        PresentationData,
        TriggerButtons,
    },
    props: {
        value: Object,
    },
    data() {
        return {
            localPresentation: null,
            draft: null,
            published: null,
        };
    },
    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.localPresentation = value;
                    this.draft = value.presentationDraft;
                    this.published = value.presentationPublished;
                }
            },
            immediate: true,
            deep: true,
        },
        localPresentation: {
            handler(localPresentation) {
                this.$emit('input', localPresentation);
            },
            deep: true,
        },
        draft: {
            handler(value) {
                if (value != null) {
                    this.localPresentation.presentationDraft = value;
                } else {
                    this.draft = {};
                }
            },
            deep: true,
        },
        published: {
            handler(value) {
                if (value != null) {
                    this.localPresentation.presentationPublished = value;
                }
            },
            deep: true,
        },
    },
    methods: {
        getActivePanelId() {
            return this.$refs.panels.getActivePanel().id;
        },
        panelChanged(value) {
            this.$emit("panel-changed", value);
        },
        
        triggerClicked(action) {
            this.localPresentation.presentationDraft.executeAction = action;
            // To be able to create a new presentation
            this.localPresentation.presentationDraft.userPk = this.userPk;
            
            apiClient.genericSave(
                '/listepro/action-on/presentation-data/',
                this.localPresentation.presentationDraft
            )
                .then(response => {
                    this.$emit("reload-presentation");
                    this.$notify({
                        group: 'root',
                        type: 'success',
                        title: 'Modifications enregistrées',
                    });
                })
                .catch(error => {
                    this.handleFormErrors(error);
                });
        },

        resetErrors() {
            this.resetErrorFields();
            this.formErrors = { value: []};
            this.formErrorsMessages = { value: []};
        },
        
        savePresentationAndSendMessage(message) {
            console.log(message);
            
            this.resetErrors();
            this.localPresentation.presentationDraft.executeAction = message.action;
            this.localPresentation.presentationDraft.executeMailSubject = message.subject;
            this.localPresentation.presentationDraft.executeMailContent = message.content;

            this.triggerClicked(message.action);          
        },

    },
};


</script>
<style lang='stylus' scoped>
@require '../../../stylesheet/variables'
@require '../../../stylesheet/typography'

.validation-pending
    font-weight: bold
    font-style: italic

.panel-presentation
    :deep(.panel-selectors)
        margin-top: space-md
</style>
